/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useEffect } from 'react';
import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
// import { datadogRum } from '@datadog/browser-rum';
import loadable from '../utils/loadable';
import { Routes as AppRoutes } from '../utils/constants/routes';

import GlobalStyle from '../global-styles';
// page imports
import DefaultLayout from '../layout/index';
import { createLogger } from '../utils/logger';
import ErrorBoundaryBadEntityId from '../error-boundaries/ErrorBoundaryBadEntityId';
import { InventoryLayout } from '../layout/InventoryLayout';
import { PeopleLayout } from '../layout/PeopleLayout';
import { FullPageLoader } from '../components/FullPageLoader';
import { useIsAllowedTo } from '../hooks/useIsAllowedTo';
import { Permission } from '../utils/constants/permissions';
import { useBootstrap } from '../hooks/useBootstrap';
import { useAllBlueprints } from '../hooks/useAllBlueprints';

const log = createLogger('routes:::');

const Dashboard = loadable(() => import('./home'));
const Canvas = loadable(() => import('./dashboard'));

const InventorySummary = loadable(() => import('./inventory/summary'));
const InventoryApplications = loadable(() => import('./inventory/applications'));
const InventoryComponents = loadable(() => import('./inventory/components'));
const InventoryPortfolios = loadable(() => import('./inventory/portfolios'));
const InventoryBusinesses = loadable(() => import('./inventory/businesses'));
const InventoryTechnologies = loadable(() => import('./inventory/technologies'));
const InventoryServices = loadable(() => import('./inventory/services'));
const InventoryCapabilities = loadable(() => import('./inventory/capabilities'));
const InventoryVendors = loadable(() => import('./inventory/vendors'));
const InventoryRepositories = loadable(() => import('./inventory/repositories'));
const InventoryVulnerabilities = loadable(() => import('./inventory/vulnerabilities'));
// const InventoryTechnologyPostures = loadable(() => import('./inventory/technology-postures'));
const ExploreDeployments = loadable(() => import('./explore/deployments'));

const ApplicationItem = loadable(() => import('./inventory/application-item'));
const ComponentItem = loadable(() => import('./inventory/components-item'));
const PortfoliosItem = loadable(() => import('./inventory/portfolios-item'));
const BusinessesItem = loadable(() => import('./inventory/businesses-item'));
const CapabilitiesItem = loadable(() => import('./inventory/capabilities-item'));
const TechnologyItem = loadable(() => import('./inventory/technology-item'));
const ServiceItem = loadable(() => import('./inventory/service-item'));
const VendorItem = loadable(() => import('./inventory/vendor-item'));
const RepositoriesItem = loadable(() => import('./inventory/repositories-item'));
const VulnerabilityItem = loadable(() => import('./inventory/vulnerability-item'));
const Settings = loadable(() => import('./settings'));
const IntegrationItem = loadable(() => import('./settings/integration-item'));
const Profile = loadable(() => import('./profile'));
const Landing = loadable(() => import('./landing'));
const ForgotPassword = loadable(() => import('./forgot-password'));

const PeopleRoster = loadable(() => import('./people/roster'));
const PeopleTeams = loadable(() => import('./people/teams'));
const PeopleRoles = loadable(() => import('./people/roles'));
const PeopleLocations = loadable(() => import('./people/locations'));
const SinglePersonView = loadable(() => import('./people/single-person-view'));
const SingleTeamView = loadable(() => import('./people/single-team-view'));

const Explore = loadable(() => import('./explore'));
const ExploreCapabilities = loadable(() => import('./explore/capabilities'));
const TeamsExplorer = loadable(() => import('./explore/teams'));
const DataFlow = loadable(() => import('./explore/data-flow'));
const CanvasNew = loadable(() => import('./explore/canvas'));

const Journeys = loadable(() => import('./journeys'));
const JourneysSummary = loadable(() => import('./journeys/summary'));
const AllJourneys = loadable(() => import('./journeys/all-journeys'));
const CreateNewJourney = loadable(() => import('./journeys/create-new-journey'));

const JourneyDetails = loadable(() => import('./journeys/journey-details'));
const JourneyDetailsSummary = loadable(() => import('./journeys/journey-details/summary'));
const JourneyDetailsApplications = loadable(() => import('./journeys/journey-details/applications'));
const JourneyDetailsChecks = loadable(() => import('./journeys/journey-details/checks'));
const JourneyDetailsRunResults = loadable(() => import('./journeys/journey-details/run-results'));

const AuthenticationGuard = () => {
  const Component = withAuthenticationRequired(Outlet, {
    onRedirecting: () => <FullPageLoader />,
  });

  return <Component />;
};

const PermissionBasedRoute = ({ children, permissions = [], redirectUrl = AppRoutes.ROOT }) => {
  const { isAllowedTo } = useIsAllowedTo();

  const isAllowed = permissions.some((p) => isAllowedTo(p));

  return isAllowed ? children : <Navigate to={redirectUrl} replace />;
};

const BootstrapLoader = () => {
  // eslint-disable-next-line no-unused-vars
  const { loading, user, account } = useBootstrap();
  useAllBlueprints();

  if (loading) {
    return <FullPageLoader />;
  }

  // if (user && account) {
  //   datadogRum.setUser({
  //     id: user.id,
  //     name: `${user.firstName} ${user.lastName}`,
  //     email: user.email,
  //     accountId: account.id,
  //     accountName: account.name,
  //   });
  // }

  return <Outlet />;
};

const Logout = () => {
  const { logout } = useAuth0();
  log.info('logout route fired.');

  useEffect(() => {
    logout();
  }, [logout]);

  return null;
};

function Router() {
  return (
    <ErrorBoundaryBadEntityId>
      <Routes>
        <Route path={AppRoutes.LANDING} element={<Landing />} />
        <Route path={AppRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={AppRoutes.LOGOUT} element={<Logout />} />

        <Route element={<AuthenticationGuard />}>
          <Route element={<BootstrapLoader />}>
            <Route element={<DefaultLayout />}>
              <Route path={AppRoutes.ROOT} element={<Dashboard />} />
              <Route path={AppRoutes.EXPLORE} element={<Explore />} />
              <Route path={AppRoutes.EXPLORE_CAPABILITIES} element={<ExploreCapabilities />} />
              <Route path={AppRoutes.EXPLORE_TEAMS} element={<TeamsExplorer />} />
              <Route path={AppRoutes.EXPLORE_DEPLOYMENTS} element={<ExploreDeployments />} />
              <Route path={AppRoutes.CANVAS} element={<Canvas />} />
              <Route path={AppRoutes.CANVAS_NEW} element={<CanvasNew />} />
              <Route path={AppRoutes.DATA_FLOW} element={<DataFlow />} />
              <Route
                path={AppRoutes.SINGLE_BUSINESS}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_BUSINESS]} redirectUrl={AppRoutes.INVENTORY}>
                    <BusinessesItem />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.SINGLE_APPLICATION}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_APPLICATION]} redirectUrl={AppRoutes.INVENTORY}>
                    <ApplicationItem />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.SINGLE_PORTFOLIO}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_PORTFOLIO]} redirectUrl={AppRoutes.INVENTORY}>
                    <PortfoliosItem />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.SINGLE_COMPONENT}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_COMPONENT]} redirectUrl={AppRoutes.INVENTORY}>
                    <ComponentItem />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.SINGLE_CAPABILITY}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_CAPABILITY]} redirectUrl={AppRoutes.INVENTORY}>
                    <CapabilitiesItem />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.SINGLE_TECHNOLOGY}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_TECHNOLOGY]} redirectUrl={AppRoutes.INVENTORY}>
                    <TechnologyItem />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.SINGLE_SERVICE}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_SERVICE]} redirectUrl={AppRoutes.INVENTORY}>
                    <ServiceItem />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.SINGLE_VENDOR}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_VENDOR]} redirectUrl={AppRoutes.INVENTORY}>
                    <VendorItem />
                  </PermissionBasedRoute>
                }
              />
              <Route path={AppRoutes.SINGLE_REPOSITORY} element={<RepositoriesItem />} />
              <Route path={AppRoutes.SINGLE_VULNERABILITY} element={<VulnerabilityItem />} />
              <Route path={AppRoutes.SETTINGS_SINGLE_INTEGRATION} element={<IntegrationItem />} />
              <Route path={`${AppRoutes.SETTINGS}/*`} element={<Settings />} />
              <Route path={AppRoutes.PROFILE} element={<Profile />} />
              <Route
                path={AppRoutes.SINGLE_PERSON}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_PERSON]} redirectUrl={AppRoutes.INVENTORY}>
                    <SinglePersonView />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={`${AppRoutes.SINGLE_TEAM}/*`}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_TEAM]} redirectUrl={AppRoutes.INVENTORY}>
                    <SingleTeamView />
                  </PermissionBasedRoute>
                }
              />
              {/* <Route path={AppRoutes.REPORTING_EXPLORE} component={ReportingExplore} />
              <Route path={AppRoutes.REPORTING_DASHBOARD} component={ReportingDashboard} /> */}
              <Route path={AppRoutes.JOURNEYS} element={<Journeys />}>
                <Route index element={<JourneysSummary />} />
                <Route path="all" element={<AllJourneys />} />
              </Route>
              <Route path={AppRoutes.JOURNEYS_NEW} element={<CreateNewJourney />} />
              <Route path={AppRoutes.SINGLE_JOURNEY} element={<JourneyDetails />}>
                <Route index element={<JourneyDetailsSummary />} />
                <Route path="applications" element={<JourneyDetailsApplications />} />
                <Route path="checks" element={<JourneyDetailsChecks />} />
                <Route path="results" element={<JourneyDetailsRunResults />} />
              </Route>
            </Route>

            <Route element={<InventoryLayout />}>
              <Route path={AppRoutes.INVENTORY} element={<InventorySummary />} />
              <Route
                path={AppRoutes.INVENTORY_PORTFOLIOS}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_PORTFOLIO]} redirectUrl={AppRoutes.INVENTORY}>
                    <InventoryPortfolios />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.INVENTORY_APPLICATIONS}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_APPLICATION]} redirectUrl={AppRoutes.INVENTORY}>
                    <InventoryApplications />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.INVENTORY_COMPONENTS}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_COMPONENT]} redirectUrl={AppRoutes.INVENTORY}>
                    <InventoryComponents />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.INVENTORY_CAPABILITIES}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_CAPABILITY]} redirectUrl={AppRoutes.INVENTORY}>
                    <InventoryCapabilities />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.INVENTORY_BUSINESSES}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_BUSINESS]} redirectUrl={AppRoutes.INVENTORY}>
                    <InventoryBusinesses />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.INVENTORY_TECHNOLOGIES}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_TECHNOLOGY]} redirectUrl={AppRoutes.INVENTORY}>
                    <InventoryTechnologies />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.INVENTORY_SERVICES}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_SERVICE]} redirectUrl={AppRoutes.INVENTORY}>
                    <InventoryServices />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.INVENTORY_VENDORS}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_VENDOR]} redirectUrl={AppRoutes.INVENTORY}>
                    <InventoryVendors />
                  </PermissionBasedRoute>
                }
              />
              <Route path={AppRoutes.INVENTORY_REPOSITORIES} element={<InventoryRepositories />} />
              <Route path={AppRoutes.INVENTORY_VULNERABILITIES} element={<InventoryVulnerabilities />} />
              {/* <Route path={AppRoutes.INVENTORY_TECH_POSTURES} element={<InventoryTechnologyPostures />} /> */}
            </Route>

            <Route element={<PeopleLayout />}>
              <Route
                path={AppRoutes.PEOPLE}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_PERSON]}>
                    <PeopleRoster />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.TEAMS}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_TEAM]}>
                    <PeopleTeams />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.ROLES}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_ROLE]}>
                    <PeopleRoles />
                  </PermissionBasedRoute>
                }
              />
              <Route
                path={AppRoutes.LOCATIONS}
                element={
                  <PermissionBasedRoute permissions={[Permission.READ_LOCATION]}>
                    <PeopleLocations />
                  </PermissionBasedRoute>
                }
              />
            </Route>

            <Route path="*" element={<Navigate to={AppRoutes.ROOT} />} />
          </Route>
        </Route>
      </Routes>
      <GlobalStyle />
    </ErrorBoundaryBadEntityId>
  );
}

export default Router;
