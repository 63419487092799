import React, { useState, useEffect } from 'react';
import * as ld from 'launchdarkly-react-client-sdk';
// import { datadogRum } from '@datadog/browser-rum';
import { useBootstrap } from '../../hooks/useBootstrap';

const clientSideID = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '';
const defaultFlags = {
  chat: false,
  maturity: false,
  repository: true,
  vuln: true,
};

export const FeatureFlagsProvider = ({ children }) => {
  const [context, setContext] = useState();
  const { user } = useBootstrap();

  useEffect(() => {
    if (user) {
      const username = `${user.firstName} ${user.lastName}`;
      setContext({ kind: 'user', key: user.email, accountId: user.accountId, name: username, email: user.email });
    }
  }, [user]);

  return (
    <ld.LDProvider
      clientSideID={clientSideID}
      deferInitialization
      context={context}
      options={{
        bootstrap: defaultFlags,
        // inspectors: [
        //   {
        //     type: 'flag-used',
        //     name: 'dd-inspector',
        //     method: (key, detail) => {
        //       datadogRum.addFeatureFlagEvaluation(key, detail.value);
        //     },
        //   },
        // ],
      }}
    >
      {children}
    </ld.LDProvider>
  );
};
